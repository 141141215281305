// Tailwind styles
.required-field {
  @apply pr-2 font-bold text-red-500 inline-block;
}

.required-field::after {
  content: "*";
  margin-left: 4px;
}

// Input error
.input-required-error {
  --tw-text-opacity: 1 !important;
  color: rgb(185 28 28 / var(--tw-text-opacity)) !important;
  --tw-border-opacity: 1 !important;
  border-color: rgb(185 28 28 / var(--tw-border-opacity)) !important;
}

.button-with-loader-when-disabled {
  @apply disabled:transition disabled:bg-zinc-700 disabled:backdrop-blur-sm disabled:opacity-50;
}

.button-with-loader-when-disabled[disabled] {
  .loader {
    @apply transition rounded border-4 border-amber-100;
    @apply disabled:animate-spin;
    @apply absolute h-0 bottom-0;
    width: 30%;
    left: 10%;
    animation: button-loader 1s infinite;
  }
}

@keyframes button-loader {
  0% {
    left: 10%;
  }
  50% {
    left: 60%;
  }
  100% {
    left: 10%;
  }
}

// Tooltip
.tailwind-tooltip {
  @apply absolute z-10 p-2 text-white bg-black rounded-md text-sm;
}

/* Arrow */
.tailwind-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
